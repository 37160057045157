.payment-status-div{
    border-radius:2.75rem;
    padding: 0.8rem 1.5rem 0.8rem 1.5rem;
    margin-top: 0.5rem;
}
.payment-text{
    color: white;
    font-weight: bold;
}
.text-bold{
    font-weight: bold;
}
.small-text{
    font-size: 0.75rem;
    color: grey;
}
.discount-top-margin{
    margin-top: 0.5rem;
}
.breakdown-total-row{
    border-top: 1px solid #E6E7E7;
    padding-top: 1.2rem;
}
.onetime-total-row{
    border-top: 1px solid #E6E7E7;
    padding: 1.3rem 0 0.2rem 0;
}
.invoice-table-th{
    color: #4F5655; 
    padding: 1rem;
}
#invoice-table > thead > tr > th{
    padding: 1rem;
    font-weight: 500;
} 
#invoice-table > tbody> tr > td {
    padding: 1rem;
}
.table-payment-status{
    font-size: 0.8rem;
	font-weight: 700;
	width: 6rem;
	height: 2rem;
	margin: 0;
	padding-top: 0.4rem;
	border-radius: 2rem;
	border: none;
}