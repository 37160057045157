.attributeSetting {
  p {
    color: rgba(79, 86, 85, 1);
  }

  .panelHeader {
    font-size: 16px;
    font-weight: 700;
  }
  //collapse
  .attributeContainer,
  .collapse {
    margin: 25px 0px;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #cdcfce;;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 16px 0px;
    cursor: pointer;
  }

  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-top: 0px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  //table
  .ant-table-thead > tr > th {
    background-color: rgba(242, 243, 236, 1);
    font-weight: 700;
    font-size: 14px;
    color: rgba(104, 110, 109, 1);
    border: none;
  }

  .ant-table-tbody > tr > td {
    border: none;
  }

  .ant-table-tbody > tr > td:first-child {
    padding-left: 10px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }
  //icon
  .tableIcon {
    color: rgba(104, 110, 109, 1);
    cursor: pointer;
    transition: 0.2s ease-out;
  }

  .disableTableIcon {
    color: rgb(236, 234, 234);
    border: 1px solid rgb(0, 0, 0);
  }

  .tableIcon:hover {
    border: 1px solid rgb(0, 0, 0);
    color: rgb(0, 0, 0);
    transition: 0.2s ease-in;
    transform: scale(1.1);
  }
  //card
  .ant-card {
    border-radius: 4px;
  }
  //container
  .emptyAttributeContainer {
    text-align: center;
    gap: 10px;
    padding: 100px;
  }
}

//Drawer

.drawer-dtype-box {
  margin: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 6px;
  width: 100px;
  height: 72px;
  border: 1px solid #e6e7e7;
  border-radius: 4px;
  cursor: pointer;
}

.drawer-selection-box {
    background: #ffffff;
    .drawer-dtype-box;
  }


.drawer-selection-box-selected {
  background: #0b7764;
  color: white;
  .drawer-dtype-box;
}
// //notification
//modal
.attributeSettingsModal {
  .deleteAttribute {
    background-color: rgba(245, 245, 245, 1);
    padding: 20px;
    border-radius: 8px;
    margin: 25px 0px;
  }

  .deleteAttributeText {
    color: #4f5655;
    font-size: 14px;
    line-height: 2;
  }

  //checkbox
  .ant-checkbox {
    top: 0.4rem;
  }
}

// notification
.notification-pannel {
  padding: 8px;
}

// drag and drop
.row-dragging {
  background: #fafafa;
  border: 1px solid rgb(255, 255, 255);
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.attribute-settings-checkbox {
  .ant-form-item {
    margin-bottom: 14px;
    margin-top: 10px;
  }
}

.attribute-banner {
  background: #f0f8f6;
  border-radius: 4px;
  height: 100px;
  margin-right: 2.8rem;
}

.form-heading {
  font-family: 'DM Sans';
  font-size: 14px;
  margin-top: 10px;
}

.radio-box {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  justify-content: left;
  align-items: start;
  margin: 10px 5px;
  padding: 15px 20px;
}

.attribute-setting-radio-box {
  background-color: #ffffff;
  border: 1px solid #9b9f9e;
  .radio-box;
}

.attribute-setting-radio-box-checked {
  background: #f0f8f6;
  border: 1px solid #0b7764;
  .radio-box;
}


.dropdown-values-banner {
  background: #f5f5f5;
  border-radius: 8px;
  margin-top: 1rem;
}

.add-dropdown-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.attribute-banner-boolean {
  background: #f0f8f6;
  border-radius: 4px;
  height: 230px;
  margin-right: 2.8rem;
}

.attribute-setting-footer-button-save {
  background-color: #000;
  color: #ffffff;
}

.attribute-setting-footer-button-save:disabled {
  background-color: #e6e7e7;
  color: #9b9f9e;
}
@primary-color: #278977;