@import '~antd/dist/antd.less';

@import "~antd/dist/antd.less";

@font-face {
  font-family: "Avenir-Roman";
  src: local("Avenir-Roman"),
    url("./assets/fonts/Avenir-Roman.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy800.ttf") format("truetype");
}

body {
 
  margin: 0;
  font-family: "Avenir-Roman";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-input{
  border-radius: 4px;
}
.ant-select{
  border-radius: 4px;
}
.ant-btn{
  border-radius: 4px;
}
.sign-in-container{
  max-width: 30em;
  max-height: 30em;
  border-radius: 8px;
  background-color: white;
  box-shadow: 2px 2px 4px 4px rgba(219, 218, 218, 0.5);
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  
  margin: auto;
}

// .ant-modal-content {
//   position: relative;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 0;
//   border-radius: 2px;
//   box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
//   pointer-events: auto;
//   height: 250px;
// }

.dragged-attribute {
     z-index: 1500;
}

// .ant-modal-footer {
//     background: #fff;
// }

.list{
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 600px;
  margin-top: 1em;

}
.modal-row {
  margin-bottom: 6px;
}

.modal-radio {
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  height: 100%;
}

.radio-class {
  height: 100%;
  align-self: center;
}

.ant-modal-content {
  border-radius: 8px;
}

@primary-color: #278977;