.text-tab-change{
    margin-top: 1rem;
    border-bottom: 1px solid #B4B7B6;
    padding: 0.4rem;
    cursor: pointer;
}
.subscription-title-font{
    font-size: 1rem;
    margin-left: 0.8rem;
}
.subscription-subtitle-font{
    margin: 0.5rem 0 0 2.1rem;
    color: #363E3D;
}
.plan-usage-btn-upgrade{
    height: 100%;
    font-weight: 700;
    
    /* margin-top: 1rem; */
}
.planusage-title-row{
    margin-bottom: 1rem;
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
    margin-top: 0;
    padding-top: 1.3rem;
}

.comment-text-title {
    font-size: 16px;
    color: #040E0C;
    font-weight: 600;
}
.comment-text-value {
    font-size: 16px;
    color: #4F5655;
    font-weight: 400;
}
.comment-box-row {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}