.spacing {
	padding: 1rem;
	margin: 1rem;
}

.inner {
  padding: 1rem;
}

.inner-left {
  padding-left: 1rem;
}

.button {
  margin: 1rem 2rem;
  color: #ffffff;
  background-color: #278977;
}

.uploadButton {
  height: 5rem;
  width: 7rem;
  border-style: dashed;
  margin: 0.5rem;
  color:#278977;
}

.previewCard {
  font-size: large;
  font-weight: 400;
  height: 5rem;
  width: auto;
  margin: 0.5rem;
  padding-top: 0.8rem;
 
  .detail {
    font-size: small;
    font-weight: 400;
    color: grey;
    padding-top: 0.1rem;
  }
}

 
@primary-color: #278977;