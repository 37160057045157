.upgrade-to {
  .card {
    background: linear-gradient(269.39deg, #e6dffb 3.37%, #ffd9a6 121.27%);
    padding: 18px 24px;
    mix-blend-mode: normal;
    border-radius: 8px;
  }
  .title{
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
}
.current{
    padding: 18px 24px;
    mix-blend-mode: normal;
    border-radius: 8px;
    background: #F5F5F5;
    .title{
        font-size: 18px;
        font-weight: 700;
        color: #000;
      }
}
@primary-color: #278977;