.excel-templates-headers-row {
  margin: 1.5em 0;
}
.excel-template-container-row {
  margin-top: 2.5em;
}
.excel-template-card {
  margin-top: 2em;
}
.excel-template-name-space {
  margin-left: 2em;
}
.excel-delete-template-subtext {
  background: rgba(245, 245, 245, 1);
  padding: 2em 1.5em;
  border-radius: 1em;
  margin: 1em 0;
}
.excel-template-delete-checkbox {
  margin-top: 1em;
}
.excel-template-upload-file-row {
  margin: 0.8em 0;
}
.excel-templates-empty-container {
  margin-top: 5em;
}

@primary-color: #278977;